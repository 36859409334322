import { useLoginMutation, useRegisterMutation } from 'apis/auth.api';
import { useDispatch, useSelector } from 'react-redux';
import { setUserRoles, setUserToken } from 'slices/auth.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { mlmApi, useGetAgentInfosQuery, useLazyGetAgentInfosQuery } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { setAgentStatus } from 'slices/mlm.slice';
import { adminApi } from 'apis/admin.api';
import { useEffect } from 'react';
export const useAuthService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loginMutation] = useLoginMutation();
  const [registerMutation] = useRegisterMutation();
  const [trigger] = useLazyGetAgentInfosQuery();
  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);
  const roles = useSelector(state => state.auth.roles);

  // useEffect(() => {
  //   const checkAuthAndRedirect = async () => {
      

  //     if (token==null || roles.length == 0) {
  //       if(location.pathname != "/" && !location.pathname.startsWith("/auth")) navigate('/auth');
  //       return;
  //     }
  //     if (roles.includes("ROLE_ADMIN")) {
  //       // if(location.pathname != "/" && !location.pathname.startsWith("/admin")) navigate('/admin');
  //     }
  //     else if (roles.includes("ROLE_AGENT")) {
  //       const agentInfos = await trigger({}, false).unwrap();
  //       console.log("AGENT INFOS AFTER ADMIN LOGIN", agentInfos);
  //       await dispatch(setAgentStatus(agentInfos?.status));
  //       // if(location.pathname != "/" && !location.pathname.startsWith("/agent")) navigate('/agent/workspace/dashboard');
  //     }
  //     await dispatch(mlmApi.util.resetApiState())
  //     await dispatch(stripeApi.util.resetApiState())
  //     await dispatch(adminApi.util.resetApiState())
  //   };

  //   checkAuthAndRedirect();
    
  // }, [token, roles]);


  const logout = async () => {
    try {
     

      await dispatch(setUserRoles([]));
      await dispatch(setUserToken(null));
      await dispatch(setAgentStatus(null));


      
    } catch (error) {
      throw error;
    }
  };

  const login = async (credentials) => {
    try {
      const result = await loginMutation(credentials).unwrap();

      await dispatch(setUserToken(result.token));
      await dispatch(setUserRoles(result.roles));     
      
      await dispatch(mlmApi.util.resetApiState())
      await dispatch(stripeApi.util.resetApiState())
      await dispatch(adminApi.util.resetApiState())
      
      return result;
    } catch (error) {
      throw error;
    }
  };

  const register = async (userData) => {
    try {
      const result = await registerMutation(userData).unwrap();
      await dispatch(setUserToken(result.token));
      await dispatch(setUserRoles(result.roles));

      return result;
    } catch (error) {
      throw error;
    }
  };

  return { login, register, logout};
};