
// project imports
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useRef, useState } from 'react';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useStripeService } from 'services/stripe.service';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useMlmService } from 'services/mlm.service';
import { LoadingButton } from '@mui/lab';
import ReactSignatureCanvas from 'react-signature-canvas';
import { borderColor, borderRadius } from '@mui/system';
import { useAdminService } from 'services/admin.service';
// ==============================|| SAMPLE PAGE ||============================== //

const AgentSalesMarginForm = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  
  const scriptedRef = useScriptRef();
  const {createAgentSalesMargin, isCreateAgentSalesMarginLoading} = useMlmService();
  return (
    <MainCard
      sx={{
        maxWidth: 400,
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
    >
      <Box sx={{ p: 3 }}>

        <h1 style={{lineHeight:"35px"}}>Formulaire de création de marge de vente</h1>
        <Formik
        initialValues={{
          amount: '',
          month: '',
          year: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.string().max(255).required("Veuillez renseigner le montant de la marge de vente."),
          month: Yup.date().required('Veuillez renseigner le mois.'),
          year: Yup.date().required("Veuillez renseigner l'année'."),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setErrorMessage(null);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            const datas = {
              "amount": values["amount"],
              "month": values["month"],
              "year": values["year"]
            }
            const res = await createAgentSalesMargin(datas);

            console.log('Result:', res);
            navigate('/agent/me/sales_margin');
          } catch (err) {
            console.log(err);
            setErrorMessage(err.data ? err.data.message : err.message);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form>
            
            <FormControl fullWidth error={Boolean(touched.amount && errors.amount)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-amount-agent-registration">Montant de la marge (en €)</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount-agent-registration"
                type="text"
                value={values.amount}
                name="amount"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Montant de la marge"
                inputProps={{}}
              />
              {touched.amount && errors.amount && (
                <FormHelperText error id="standard-weight-helper-text-amount-agent-registration">
                  {errors.amount}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.month && errors.month)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-month-agent-registration">Mois</InputLabel>
              <OutlinedInput
                id="outlined-adornment-month-agent-registration"
                type="text"
                value={values.month}
                name="month"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Mois"
                inputProps={{}}
              />
              {touched.month && errors.month && (
                <FormHelperText error id="standard-weight-helper-text-month-agent-registration">
                  {errors.month}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={Boolean(touched.year && errors.year)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-year-agent-registration">Année</InputLabel>
              <OutlinedInput
                id="outlined-adornment-year-agent-registration"
                type="text"
                value={values.year}
                name="year"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Année"
                inputProps={{}}
              />
              {touched.year && errors.year && (
                <FormHelperText error id="standard-weight-helper-text-year-agent-registration">
                  {errors.year}
                </FormHelperText>
              )}
            </FormControl>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <LoadingButton disableElevation loading={isCreateAgentSalesMarginLoading} fullWidth size="large" type="submit" variant="contained" color="secondary" style={{textTransform:'none'}}>
                  Créer
                </LoadingButton>
              </AnimateButton>
            </Box>
          </Form>
        )}
      </Formik>
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}

     </Box>
    </MainCard >
  );
}
export default AgentSalesMarginForm;
