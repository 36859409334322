// material-ui
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useFindAgentsQuery, useFindOrdersQuery, useGetChiffreAffaireByYearQuery } from 'apis/mlm.api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMlmService } from 'services/mlm.service';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentChiffreAffaireByYearPage = () => {
  const theme = useTheme();
  const [yearOfDateCompleted, setYearOfDateCompleted] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [chiffreAffaireList, setChiffreAffaireList] = useState([]);
  const [count, setCount] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const { data: chiffreAffaireDatas, error, isLoading } = useGetChiffreAffaireByYearQuery({ page: page - 1, limit: limit, year_of_date_completed: yearOfDateCompleted});
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(()=>{
    if (error) {
      console.log("error", error)
      setErrorMessage(error.data.message);
    }
  }, [error]);

  useEffect(() => {
    if (chiffreAffaireDatas == null) return;
    console.log("chiffreAffaireDatas", chiffreAffaireDatas)
    setChiffreAffaireList(chiffreAffaireDatas['ca']);
    setCount(chiffreAffaireDatas['count']);
    setNbPages(chiffreAffaireDatas['nb_pages']);
  }, [chiffreAffaireDatas]);

  const handlePageChange = (e, value) => {
    setPage(value);
  }

  return (
    <div>
       {isLoading  && <Alert severity="info" style={{ marginTop: 10 }}>En cours de chargement ...</Alert>}
      {(!isLoading && (!chiffreAffaireList || chiffreAffaireList.length == 0)) && <Alert severity="info" style={{ marginTop: 10 }}>Aucun historique disponible.</Alert>}
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}
      {chiffreAffaireList && chiffreAffaireList.length != 0 &&
        <>
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
              <TableHead>
                <TableRow>
                <TableCell>Année</TableCell>
                <TableCell>Chiffre d'affaire perso</TableCell>
                <TableCell>Chiffre d'affaire clientes</TableCell>
                <TableCell>Chiffre d'affaire total</TableCell>
                

                </TableRow>
              </TableHead>
              <TableBody>
                {chiffreAffaireList.map((ca, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ width: 200 }}><b>{ca.year_of_date_completed ?? "-"}</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{Math.round((ca.own_sales ?? 0)*100)/100}€</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{Math.round((ca.sponsored_sales ?? 0)*100)/100} €</b></TableCell>
                    <TableCell style={{ width: 25 }} ><b>{Math.round((ca.total_sales ?? 0)*100)/100} €</b></TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={nbPages} color="primary" onChange={handlePageChange} />
          </div>
        </>
      }

    </div>
  );
}

export default AgentChiffreAffaireByYearPage;
