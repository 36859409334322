import { adminApi, useActivateAgentMutation, useApproveSupportingDocumentMutation, useChangeSponsorOfAgentMutation, useChangeUsernameOfAgentMutation, useCreatePromoCodeMutation, useDeactivateAgentMutation, useDeletePromoCodeMutation, useLoginWithAdminPrivilegesMutation, useRejectSupportingDocumentMutation, useRequestOTPMutation } from 'apis/admin.api';
import { mlmApi, useLazyGetAgentInfosQuery } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { is } from 'immutable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setUserRoles, setUserToken } from 'slices/auth.slice';
import { setAgentStatus } from 'slices/mlm.slice';
export const useAdminService = () => {
  const navigate = useNavigate();
  const [loginWithAdminPrivilegesMutation, { isLoading: isLoginWithAdminPrivilegesMutationLoading }] = useLoginWithAdminPrivilegesMutation();
  const [activateAgentMutation, { isLoading: isActivateAgentMutationLoading }] = useActivateAgentMutation();

  const [deactivateAgentMutation, { isLoading: isDeactivateAgentMutationLoading }] = useDeactivateAgentMutation();
  const [deletePromoCodeMutation, { isLoading: isDeletePromoCodeLoading }] = useDeletePromoCodeMutation();
  const [createPromoCodeMutation, { isLoading: isCreatePromoCodeLoading }] = useCreatePromoCodeMutation();
  const [approveSupportingDocumentMutation, { isLoading: isApproveSupportingDocumentMutationLoading }] = useApproveSupportingDocumentMutation();
  const [rejectSupportingDocumentMutation, { isLoading: isRejectSupportingDocumentMutationLoading }] = useRejectSupportingDocumentMutation();
  const [changeUsernameOfAgentMutation, { isLoading: isChangeUsernameOfAgentMutationLoading }] = useChangeUsernameOfAgentMutation();
  const [changeSponsorOfAgentMutation, { isLoading: isChangeSponsorOfAgentMutationLoading }] = useChangeSponsorOfAgentMutation();
  
  const [requestOTPMutation, { isLoading: isRequestOTPMutationLoading }] = useRequestOTPMutation();

  const [trigger] = useLazyGetAgentInfosQuery();


  const dispatch = useDispatch();
  const loginWithAdminPrivileges = async (datas) => {
    try {
      const result = await loginWithAdminPrivilegesMutation(datas).unwrap();

      // Batch user-related dispatches
      await Promise.all([
        dispatch(setUserToken(result.token)),
        dispatch(setUserRoles(result.roles)),
        dispatch(mlmApi.util.resetApiState()),
        dispatch(stripeApi.util.resetApiState()),
        dispatch(adminApi.util.resetApiState())
      ]);

      
      return result;
    } catch (error) {
      throw error;
    }
  };
  const requestOTP = async (datas) => {
    try {
      const result = await requestOTPMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const changeUsernameOfAgent = async (datas) => {
    try {
      const result = await changeUsernameOfAgentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const changeSponsorOfAgent = async (datas) => {
    try {
      const result = await changeSponsorOfAgentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };


  const approveSupportingDocument = async (datas) => {
    try {
      const result = await approveSupportingDocumentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const rejectSupportingDocument = async (datas) => {
    try {
      const result = await rejectSupportingDocumentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const activateAgent = async (datas) => {
    try {
      const result = await activateAgentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const deactivateAgent = async (datas) => {
    try {
      const result = await deactivateAgentMutation(datas).unwrap();

      return result;
    } catch (error) {
      throw error;
    }
  };

  const deletePromoCode = async (datas) => {
    try {
      const result = await deletePromoCodeMutation(datas).unwrap();

      return result;
    } catch (error) {
      throw error;
    }
  };
  const createPromoCode = async (datas) => {
    try {
      const result = await createPromoCodeMutation(datas).unwrap();

      return result;
    } catch (error) {
      throw error;
    }
  };


  return {  changeSponsorOfAgent, isChangeSponsorOfAgentMutationLoading,requestOTP, isRequestOTPMutationLoading, loginWithAdminPrivileges, isLoginWithAdminPrivilegesMutationLoading, changeUsernameOfAgent, isChangeUsernameOfAgentMutationLoading, approveSupportingDocument, rejectSupportingDocument, isApproveSupportingDocumentMutationLoading, isRejectSupportingDocumentMutationLoading, activateAgent, deactivateAgent, isActivateAgentMutationLoading, isDeactivateAgentMutationLoading, deletePromoCode, isDeletePromoCodeLoading, createPromoCode, isCreatePromoCodeLoading };
};