// material-ui
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useFindAgentInvoicesQuery } from 'apis/mlm.api';

import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMlmService } from 'services/mlm.service';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentInvoicesListPage = () => {
  const theme = useTheme();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [invoices, setAgentInvoices] = useState([]);
  const [count, setCount] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const { data: invoices_datas, isLoading } = useFindAgentInvoicesQuery({ page: page - 1, limit: limit});
  const { deletedAgentInvoice, isDeletedAgentInvoiceLoading} = useMlmService();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (invoices_datas == null) return;
    console.log(invoices_datas)
    setAgentInvoices(invoices_datas['invoices']);
    setCount(invoices_datas['count']);
    setNbPages(invoices_datas['nb_pages']);
  }, [invoices_datas]);

  const handlePageChange = (e, value) => {
    setPage(value);
  }
  const invoiceStatus = {
    "0": "Crée",
    "1": "Acceptée",
    "-1": "Rejetée",
  }
  return (
    <MainCard title="Liste des factures">
       <Button disableElevation type="submit" variant="outlined" color="secondary"  onClick={()=>navigate('/agent/me/invoices/new')}>
                  Nouvelle facture
                </Button>
      {!isLoading && (!invoices || invoices.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucune facture trouvée.</Alert>}
      {(isLoading) && <Alert severity="info" style={{ marginTop: 10 }}>En cours de chargement ...</Alert>}
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}
      {invoices && invoices.length != 0 &&
        <>
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
              <TableHead>
                <TableRow>
                  <TableCell>Date de la facture</TableCell>
                  <TableCell>Statut de la facture</TableCell>
                  <TableCell>Nom du fichier</TableCell>
                  <TableCell></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ width: 25 }} ><b>{invoice?.month.toString().padStart(2, "0") + "/" + invoice.year}</b></TableCell>
                    <TableCell style={{ width: 200 }}><b>{invoiceStatus[invoice?.status]}</b></TableCell>
                    <TableCell style={{ width: 200 }}><b>{invoice?.filename}</b></TableCell>

                    
                    {
                       <TableCell style={{ width: 175 }}>
                        <div style={{display:"flex"}}>

                        
                        <AnimateButton>
                          <LoadingButton loading={isDeletedAgentInvoiceLoading} variant="contained" color="secondary" sx={{ boxShadow: 'none' }}  style={{ textTransform: 'none', marginRight:10 }}
                            onClick={async () => {
                              try{
                                await deletedAgentInvoice(invoice.id);
                              } catch (err) {
                                console.log(err);
                                setErrorMessage(err.data ? err.data.message : err.message);
                              }
                            }}>
                            Supprimer
                          </LoadingButton>
                        </AnimateButton>
                        <AnimateButton>
                          <Button variant="contained" disabled={invoice.filepath == null} color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                            onClick={async () => {
                              window.open(`${process.env.REACT_APP_SERVER_URL}/${invoice.filepath}`, "_blank")
                            }}>
                            Consulter la facture
                          </Button>
                        </AnimateButton>
                        </div>
                      </TableCell>
                    }
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={nbPages} color="primary" onChange={handlePageChange} />
          </div>
        </>
      }

    </MainCard>
  );
}

export default AgentInvoicesListPage;
