import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from 'slices/auth.slice';
import mlmReducer from 'slices/mlm.slice';

import { authApi } from 'apis/auth.api'; 
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';
import { mlmApi } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { adminApi } from 'apis/admin.api';
import { publicApi } from 'apis/public.api';
// ==============================|| COMBINE REDUCER ||============================== //

const persistConfig = {
  key: 'root',
  storage:sessionStorage,
  whitelist: ['auth', 'mlm']
}

const reducer = combineReducers({
  customization: customizationReducer,
  auth : authReducer,
  mlm : mlmReducer,

  [authApi.reducerPath]: authApi.reducer,
  [mlmApi.reducerPath]: mlmApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,




});
const persistedReducer = persistReducer(persistConfig, reducer)
export default persistedReducer;
