// assets
import { IconDashboard, IconBasket, IconNetwork , IconWorld, IconUser, IconUsersGroup} from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconBasket , IconNetwork, IconWorld};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const workspace = {
  id: 'workspace',
  title: 'Mon espace de travail',
  type: 'group',
  roles: ['ROLE_AGENT'],
  children: [
    {
      id: 'dashboard',
      title: 'Tableau de bord',
      type: 'item',
      url: '/agent/workspace/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'network',
      title: 'Mon réseau',
      type: 'item',
      url: '/agent/workspace/network',
      icon: icons.IconNetwork,
      breadcrumbs: false
    },
    {
      id: 'shop',
      title: 'Ma boutique en ligne',
      type: 'item',
      url: '/agent/workspace/shop',
      icon: icons.IconWorld,
      breadcrumbs: false
    },
    {
      id: 'details',
      title: 'A propos de moi',
      type: 'item',
      url: '/agent/workspace/details',
      icon: IconUser,
      breadcrumbs: false
    },
    {
      id: 'customer_files',
      title: 'Fiches clients',
      type: 'item',
      url: '/agent/workspace/customer_files',
      icon: IconUsersGroup,
      breadcrumbs: false
    },
  ]
};

export default workspace;
