// material-ui
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFindAgentQuery } from 'apis/admin.api';
import { useGetAgentDetailsQuery, useGetCASummaryByMonthQuery, useGetCustomerFileQuery } from 'apis/mlm.api';
import { use } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
// ==============================|| SAMPLE PAGE ||============================== //

const AgentCASummary = () => {
  const [month, setMonth] = useState(dayjs().month() + 1);
  const [year, setYear] = useState(dayjs().year());


  const navigate = useNavigate();
  const params = useParams();

  // State to store the selected date value
  const [value, setValue] = useState(dayjs());
  const { data: summary, isLoading, isFetching } = useGetCASummaryByMonthQuery({ month: month, year: year });
  useEffect(() => {
    if (summary == null) return;
    console.log(summary);
  }, [summary]);


  // Handle date change
  const handleDateChange = (newValue) => {
    setValue(newValue);

    // Access the date value in different formats if needed
    if (newValue) {
      setMonth(newValue.month() + 1);
      setYear(newValue.year());

      // console.log('Date object:', newValue.toDate());
      // console.log('ISO string:', newValue.toISOString());
      // console.log('Formatted date:', newValue.format('YYYY-MM-DD'));
    }
  };

  return (
    <MainCard title={`Chiffre d'affaire & Commissions`}>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label={'Date'} value={value} views={['month', 'year']} onChange={handleDateChange} sx={{

          '& .MuiInputBase-root': {
            backgroundColor: '#383838',
            color: '#ffffff',

          },
          '& .MuiOutlinedInput-input': {
            backgroundColor: '#383838',
            color: '#ffffff',
          },
          '& .MuiIconButton-root': {
            color: '#ffffff',
          },

        }} />
      </LocalizationProvider>
      {(isLoading || isFetching) && <Alert severity="info" style={{ marginTop: 10 }}>En cours de chargement ...</Alert>}

      {(!isLoading && !isFetching) && summary &&
        <>

          <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key="position" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Position de l'égerie du mois</TableCell>
                  <TableCell align="left"><b>{summary.position ? summary.position.name : "Little égerie"}</b></TableCell>
                </TableRow>
                <TableRow key="own_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Achats perso</TableCell>
                  <TableCell align="left">{summary.own_sales} € ({summary.own_num_items_sold} articles)</TableCell>
                </TableRow>
                <TableRow key="sponsored_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Achats clients</TableCell>
                  <TableCell align="left">{summary.sponsored_sales} € ({summary.sponsored_num_items_sold} articles)</TableCell>
                </TableRow>
                <TableRow key="total_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Chiffre d'affaire personnel (Achats perso + achats clients)</TableCell>
                  <TableCell align="left"><b>{summary.total_sales} € ({summary.num_items_sold} articles)</b></TableCell>
                </TableRow>
                <TableRow key="ca_team" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Chiffre d'affaire équipe</TableCell>
                  <TableCell align="left"><b>{summary.ca_team} €</b></TableCell>
                </TableRow>
                <TableRow key="ca_global" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Chiffre d'affaire global (CA Perso + CA Equipe)</TableCell>
                  <TableCell align="left"><b>{summary.ca_global} €</b></TableCell>
                </TableRow>
                <TableRow key="commission_sponsored_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Commissions sur achats clients (<b>{summary.commission_percentage_for_sponsored_sales * 100} %</b> des achats clients)</TableCell>
                  <TableCell align="left"><b>{Math.round((summary.commission_sponsored_sales ?? 0) * 100) / 100} €</b></TableCell>
                </TableRow>
                {
                  // Avant le 1er Mars 2025, les commissions sur Chiffre d'affaire Equipe n'existait pas encore
                  !value.isBefore("2025-03-01") &&
                  <TableRow key="commission_team" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">Commissions sur chiffre d'affaire équipe (<b>{summary.commission_percentage_for_ca_team == 0 ? `CA Perso minimum de ${summary.total_sales_min_for_commission_ca_team} € non atteint` : `${summary.commission_percentage_for_ca_team * 100} % du CA équipe`}</b>) </TableCell>
                    <TableCell align="left"><b>{Math.round((summary.commission_ca_team ?? 0) * 100) / 100} €</b></TableCell>
                  </TableRow>
                }


              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {
            summary.position_bonuses.length != 0 &&
            <>
              <h3>Liste des bonus par palier atteint :</h3>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell>Libellé</TableCell>

                      <TableCell>Montant</TableCell>
                      <TableCell>Déjà atteint</TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {summary.position_bonuses.map((bonus, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell style={{ textDecoration: bonus.already_reached ? "line-through" : "unset" }}><b>{bonus.label}</b></TableCell>

                        <TableCell style={{ textDecoration: bonus.already_reached ? "line-through" : "unset" }} ><b>{Math.round((bonus.amount ?? 0) * 100) / 100}€</b></TableCell>
                        <TableCell  ><b>{bonus.already_reached ? 'Oui' : 'Non'}</b></TableCell>


                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }

        </>
      }




    </MainCard>
  );
}

export default AgentCASummary;
