import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MainCard from "ui-component/cards/MainCard";
import AgentChiffreAffaireByYearPage from "./chiffre_affaire_by_year";
import AgentChiffreAffaireByDayPage from "./chiffre_affaire_by_day";
import AgentChiffreAffaireByMonthPage from "./chiffre_affaire_by_month";

const AgentChiffreAffairePage = () => {
    const [value, setValue] = React.useState('year');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <MainCard title="Historique des chiffres d'affaire">
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Par année" value="year" />
                    <Tab label="Par mois" value="month" />
                    <Tab label="Par jour" value="day" />
                </TabList>
            </Box>
            <TabPanel value="year">
                <AgentChiffreAffaireByYearPage />
            </TabPanel>
            <TabPanel value="month">
                <AgentChiffreAffaireByMonthPage />
            </TabPanel>
            <TabPanel value="day">
                <AgentChiffreAffaireByDayPage />
            </TabPanel>
        </TabContext>

    </MainCard>
};

export default AgentChiffreAffairePage;