
// project imports
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import AdminChangeSponsorOfAgentForm from './AdminChangeSponsorOfAgentForm';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect, useState } from 'react';
import { useGetAgentDetailsQuery, useGetAgentInfosQuery } from 'apis/mlm.api';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useStripeService } from 'services/stripe.service';

// ==============================|| SAMPLE PAGE ||============================== //

const AdminChangeSponsorOfAgent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { data: agentInfos, error: getAgentInfosError, isLoading: getAgentInfosIsLoading } = useGetAgentInfosQuery();
  
  const { createCheckoutSession } = useStripeService();
  const [errorMessage, setErrorMessage] = useState(null);
  return (
    <MainCard
      sx={{
        maxWidth: { xs: 400, lg: 475 },
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
    >
      <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
        <Grid container spacing={2} alignItems="center" justifyContent="start">
          <Grid item sx={{ mb: 3 }}>
            <Link to="#">
              <Logo />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="start">
              <Grid item>
                <Stack alignItems="start" justifyContent="end" spacing={1}>
                  <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                    Mets à jour tes informations <br /> 
                  </Typography>
                  <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'left' : 'inherit'}>
                    Change de parrain
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <AdminChangeSponsorOfAgentForm />

          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid></Box>
    </MainCard >
  );
}
export default AdminChangeSponsorOfAgent;
