// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ roles, agentStatus }) => {
  
  const navItems = menuItem.items.filter(item=>item.roles.filter((element) => roles.includes(element)).length>0).map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} roles={roles} agentStatus={agentStatus}/>;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
