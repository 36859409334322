// material-ui
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, FormControl, InputLabel, OutlinedInput, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFindAgentQuery } from 'apis/admin.api';
import { useGetAgentDetailsQuery } from 'apis/mlm.api';
import AgentRibForm from './AgentRibForm';
import { useMlmService } from 'services/mlm.service';
import { Box, useMediaQuery } from '@mui/system';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useTheme } from '@emotion/react';
// ==============================|| SAMPLE PAGE ||============================== //

const AgentDetailsPage = () => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { data: agent } = useGetAgentDetailsQuery();
  const { addNewSupportingDocument, isAddNewSupportingDocumentMutationLoading, deleteSupportingDocument, isDeleteSupportingDocumentMutationLoading } = useMlmService();
  useEffect(() => {
    if (agent == null) return;
    console.log(agent);
  }, [agent]);
  const [errorMessage, setErrorMessage] = useState(null);
  const agentStatus = {
    "0": "Créé",
    "1": "Actif",
    "2": "En attente de validation",
    "-1": "Inactif",
  }
  const documentStatus = {
    "-1": "Invalide",
    "1": "Valide",
    "0": "En attente de validation",


  }
  const documentTypes = {
    "1": "Identité",
    "2": "KBis",
    "3": "Carte Vitale",
    "4": "SIREN VDI",

  }
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleFormSubmit = () => {
    if (scriptedRef.current) {
      scriptedRef.current.submitForm();
    }
  };

  const [selectedSupportingDocuments, setSelectedSupportingDocuments] = useState([]);
  const updateSupportingDocuments = (files) => {
    let tmp = [];
    for (let file of files) {
      tmp.push(file);
    }
    setSelectedSupportingDocuments(tmp);
  }

  return (
    <MainCard title={`A propos de moi`}>
      {agent &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key="agentId" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Mon identifiant</TableCell>
                  <TableCell align="left"><b>{agent.id}</b></TableCell>
                </TableRow>
                
                <TableRow key="registrationDate" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Date de l'inscription</TableCell>
                  <TableCell align="left"><b>{agent?.registrationDate ? moment(agent.registrationDate, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</b></TableCell>
                </TableRow>
                {/* <TableRow key="registrationNumber" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Numéro d'inscription</TableCell>
                  <TableCell align="left"><b>{agent.registrationNumber}</b></TableCell>
                </TableRow> */}
                <TableRow key="sponsor" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Parrain</TableCell>
                  <TableCell align="left"><b>{agent.sponsor ? agent.sponsor.username : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="username" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom d'utilisateur</TableCell>
                  <TableCell align="left"><b>{agent.username ? agent.username : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="email" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Email</TableCell>
                  <TableCell align="left"><b>{agent.user.email ? agent.user.email : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="lastname" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom de famille</TableCell>
                  <TableCell align="left"><b>{agent.user.lastname ? agent.user.lastname : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="firstnames" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Prénom(s)</TableCell>
                  <TableCell align="left"><b>{agent.user.firstnames ? agent.user.firstnames : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="status" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Statut</TableCell>
                  <TableCell align="left"><Chip label={agentStatus[agent.status]} color="primary" /></TableCell>
                </TableRow>


              </TableBody>
            </Table>
          </TableContainer>

          <AgentRibForm />

          <br />
          <h3>Liste des pièces justificatives :</h3>
          <Button variant="contained" color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
            onClick={handleClickOpen}>
            Ajouter une pièce justificative
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              onSubmit: async (event) => {

                try {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries((formData).entries());
                  console.log(formJson);
                  const otp = formJson.otp;
                  let datas = {
                    "agentId": agent.id,
                    "otp": otp
                  }

                  let res = await addNewSupportingDocument(datas);
                  console.log("RES AUTH LOGIN WITH ADMIN PRIVILEGE", res);
                  handleClose();
                }
                catch (err) {
                  setErrorMessage(err.data ? (err.data.message ? err.data.message : err.data) : err.message);
                }
              },
            }}
          >
            <DialogTitle style={{ color: "white" }}>Ajout d'une pièce justificative</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "white" }}>
                Sélectionner le type du document à uploader, et téléversez le fichier
              </DialogContentText>
              <Formik
              innerRef={scriptedRef}
                initialValues={{
                  supporting_documents: "",
                  supporting_document_file_type: "",
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  supporting_documents: Yup.mixed().required("Veuillez renseigner au moins une pièce justificative."),
                  supporting_document_file_type: Yup.mixed().required("Veuillez renseignerle type de la pièce justificative à téléverser."),

                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                  try {
                    setErrorMessage(null)
                    setSubmitting(true);

                    const infos = {
                      "supporting_documents": selectedSupportingDocuments,
                      "supporting_document_file_type": values["supporting_document_file_type"]
                    }
                    console.log( "INFOSSS", infos);
                    const formData = new FormData();
                    for (let file of selectedSupportingDocuments) {
                      formData.append("supporting_documents[]", file, file.name);
                    }
                    formData.append("supporting_document_file_type", values["supporting_document_file_type"]);
                    const res = await addNewSupportingDocument(formData); // infos

                    console.log('Result:', res);
                    setStatus({ success: true });
                    handleClose();
                  } catch (err) {
                    setErrorMessage(err.data ? err.data.message : err.message);
                    if (scriptedRef.current) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (

                    <Grid container spacing={matchDownSM ? 0 : 2}>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth error={Boolean(touched.supporting_document_file_type && errors.supporting_document_file_type)} style={{ marginBottom: 10, marginTop: 20 }} >
                          <InputLabel id="supporting_document_type_label" shrink style={{top:"-8px"}}>Type de pièce justificative</InputLabel>
                          <Select
                            labelId="supporting_document_type_label"
                            id="supporting_document_type"
                            value={values.supporting_document_file_type}
                            label="Votre statut"
                            onChange={handleChange}
                            name="supporting_document_file_type"
                          >
                            <MenuItem value={"identity"}>Pièce d'identité</MenuItem>
                            <MenuItem value={"kbis"}>KBis</MenuItem>
                            <MenuItem value={"carte_vitale"}>Carte Vitale</MenuItem>
                            <MenuItem value={"siren_vdi"}>SIREN VDI</MenuItem>

                          </Select>
                        </FormControl>
                        {touched.supporting_document_file_type && errors.supporting_document_file_type && (
                          <FormHelperText error id="standard-weight-helper-text-supporting_document_file_type-agent-registration">
                            {errors.supporting_document_file_type}
                          </FormHelperText>
                        )}
                        <FormControl fullWidth error={Boolean(touched.supporting_documents && errors.supporting_documents)} sx={{ ...theme.typography.customInput }} >
                          <Box sx={{ mb: 1 }}>
                            <InputLabel htmlFor="outlined-adornment-supporting_documents-register" shrink={true}>
                              Pièce(s) justificatives
                            </InputLabel>
                          </Box>
                          <OutlinedInput
                            id="outlined-adornment-supporting_documents-register"
                            type="file"
                            value={values.supporting_documents}
                            name="supporting_documents"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              updateSupportingDocuments(e.target.files);
                            }}
                            inputProps={{
                              accept: '.pdf,.jpg,.jpeg,.png', // Restrict file types
                              multiple: true, // Allow multiple files
                            }}
                          />
                          {touched.supporting_documents && errors.supporting_documents && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                              {errors.supporting_documents}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                )}
              </Formik>

              {errorMessage && <Alert severity="error" style={{ marginTop: 10 }}>{errorMessage}</Alert>}

            </DialogContent>
            <DialogActions>
              <Button disabled={isAddNewSupportingDocumentMutationLoading} onClick={handleClose} style={{ color: "white" }}>Annuler</Button>
              <Button  disabled={isAddNewSupportingDocumentMutationLoading}  onClick={handleFormSubmit} style={{ color: "white" }}>Téleverser</Button>
            </DialogActions>
          </Dialog>

          {(agent.supportingDocuments.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucune pièce justificative mentionnée.</Alert>}
          {(agent.supportingDocuments.length != 0) &&
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Nom du fichier</TableCell>
                    <TableCell>Type du document</TableCell>
                    <TableCell>Statut</TableCell>

                    <TableCell></TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {agent.supportingDocuments.map((document, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{document.filename}</TableCell>
                      <TableCell >{documentTypes[document.documentType]}</TableCell>

                      <TableCell >{documentStatus[document.status]}</TableCell>

                      {
                        <TableCell style={{ maxWidth: 275 }}>
                          <div style={{display:"flex"}}>
                          <AnimateButton>
                            <Button variant="contained" disabled={document.filepath == null || isDeleteSupportingDocumentMutationLoading} color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', marginRight:10 }}
                              onClick={async () => {
                                try{
                                  await deleteSupportingDocument(document.id);
                                }
                                catch(err){
                                  setErrorMessage(err.data ? err.data.message : err.message);
                                }
                              }}>
                              Supprimer
                            </Button>
                          </AnimateButton>
                          <AnimateButton>
                            <Button variant="contained" disabled={document.filepath == null} color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                              onClick={async () => {
                                window.open(`${process.env.REACT_APP_SERVER_URL}/${document.filepath}`, "_blank")
                              }}>
                              Consulter le document
                            </Button>
                          </AnimateButton>
                          </div>
                        </TableCell>
                      }

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          <br />
          <h3>Liste des contrats :</h3>
          <div style={{ marginTop: 10 }}>
            <Button variant="contained" color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
              onClick={async () => {
                navigate("/agent/workspace/contract");
              }}>
              Remplir le contrat de revendeur
            </Button>
          </div>
          {(agent.agentContracts.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucun contrat signé.</Alert>}
          {(agent.agentContracts.length != 0) &&
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Date du contrat</TableCell>
                    <TableCell>Nom et prénoms</TableCell>
                    <TableCell>Adresse</TableCell>
                    <TableCell>Code postal</TableCell>
                    <TableCell>Ville</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>N°SIREN</TableCell>



                    <TableCell></TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {agent.agentContracts.map((document, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{document?.createdAt ? moment(document.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</TableCell>
                      <TableCell >{document.lastname}</TableCell>
                      <TableCell >{document.address}</TableCell>
                      <TableCell >{document.postal_code}</TableCell>
                      <TableCell >{document.city}</TableCell>
                      <TableCell >{document.email}</TableCell>
                      <TableCell >{document.phone_number}</TableCell>
                      <TableCell >{document.siren}</TableCell>


                      {
                        <TableCell style={{ maxWidth: 275 }}>
                          <AnimateButton>
                            <Button variant="contained" disabled={document.filepath == null} color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                              onClick={async () => {
                                window.open(`${process.env.REACT_APP_SERVER_URL}/${document.filepath}`, "_blank")
                              }}>
                              Voir le contrat
                            </Button>
                          </AnimateButton>
                        </TableCell>
                      }

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </>
      }




    </MainCard>
  );
}

export default AgentDetailsPage;
