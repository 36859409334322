import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user.png';

// assets
import { IconLogout, IconNetwork, IconSearch, IconSettings, IconUser } from '@tabler/icons-react';
import { useAuthService } from 'services/auth.service';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const roles = useSelector((state) => state.auth.roles);
  const agentStatus = useSelector((state) => state.mlm.agentStatus);

  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState('');
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const { logout } = useAuthService();
  const [errorMessage, setErrorMessage] = useState(null);
  const { data: agentInfos, error: getAgentInfosError, isLoading: getAgentInfosIsLoading, refetch } = useGetAgentInfosQuery();
  
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      setErrorMessage(err.data ? err.data.message : err.message);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4"  sx={{ fontWeight: 400 }}>Bienvenue </Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 900 }}>
                          {agentInfos?.user?.firstnames}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">Nom d'utilisateur: <b>{agentInfos?.username}</b></Typography>

                      <Typography variant="subtitle2">Email: {agentInfos?.user?.email}</Typography>
                      {roles.includes("ROLE_AGENT") && agentInfos?.sponsor && <Typography variant="subtitle2">Parrainé par <b>{agentInfos?.sponsor?.username}</b></Typography>}
                      <br />
                      {roles.includes("ROLE_AGENT") && <Typography variant="subtitle2">Statut juridique: <b>{agentInfos?.legalStatus}</b></Typography>}

                    </Stack>
                  </Box>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 2 }}>
                      {roles.includes("ROLE_AGENT") && !agentInfos && <UpgradePlanCard />}
                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={(event) => handleListItemClick(event, 0, '/settings/change-password')}
                        >
                          <ListItemIcon style={{color:theme.palette.main.white}}>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Changer mon mot de passe</Typography>} />
                        </ListItemButton>
                        {roles.includes("ROLE_AGENT") && 
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 0}
                          onClick={(event) => handleListItemClick(event, 0, '/agent/settings/change-username')}
                        >
                          <ListItemIcon style={{color:theme.palette.main.white}}>
                            <IconUser stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Changer mon nom d'utilisateur</Typography>} />
                        </ListItemButton>
                        }
                        {
                          roles.includes("ROLE_AGENT") && agentStatus == 1 &&
                          <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 1}
                            onClick={(event) => handleListItemClick(event, 1, '/agent/workspace/network')}
                          >
                            <ListItemIcon style={{color:theme.palette.main.white}}>
                              <IconNetwork stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid container spacing={1} justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="body2">Mon réseau</Typography>
                                  </Grid>

                                </Grid>
                              }
                            />
                          </ListItemButton>
                        }

                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon style={{color:theme.palette.main.white}}>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Se déconnecter</Typography>} />
                        </ListItemButton>
                      </List>
                      {errorMessage && <Alert severity="error" style={{ marginTop: 10 }}>{errorMessage}</Alert>}
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

    </>
  );
};

export default ProfileSection;
