// material-ui
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFindAgentQuery } from 'apis/admin.api';
import { useAdminService } from 'services/admin.service';
import { useDispatch } from 'react-redux';
import { setAgentStatus } from 'slices/mlm.slice';
import AdminAgentCommission from './AdminAgentCommission';
import AdminAgentBonuses from './AdminAgentBonuses';

// ==============================|| SAMPLE PAGE ||============================== //

const AdminAgentDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { data: agent } = useFindAgentQuery(params.agentId);
  const { requestOTP, isRequestOTPMutationLoading,loginWithAdminPrivileges, isLoginWithAdminPrivilegesMutationLoading, activateAgent, deactivateAgent, isActivateAgentMutationLoading, isDeactivateAgentMutationLoading, approveSupportingDocument, rejectSupportingDocument, isApproveSupportingDocumentMutationLoading, isRejectSupportingDocumentMutationLoading } = useAdminService();
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    if (agent == null) return;
    console.log(agent);
  }, [agent]);

  const agentStatus = {
    "0": "Créé",
    "1": "Actif",
    "2": "En attente de validation",
    "-1": "Inactif",
  }
  const documentStatus = {
    "-1": "Invalide",
    "1": "Valide",
    "0": "En attente de validation",


  }
  const documentTypes = {
    "1": "Identité",
    "2": "KBis",
    "3": "Carte Vitale",
    "4": "SIREN VDI",

  }
  const activateAgentText = {
    "0": "Approuver de suite l'agent",
    "1": "L'agent est déjà actif",
    "2": "Approuver l'agent",
    "-1": "Réactiver l'agent",
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MainCard title={`Détails de l'agent`}>
      {agent &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key="agentId" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Identifiant de l'agent</TableCell>
                  <TableCell align="left"><b>{agent.id}</b></TableCell>
                </TableRow>
               
                <TableRow key="registrationDate" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Date de l'inscription</TableCell>
                  <TableCell align="left"><b>{agent?.registrationDate ? moment(agent.registrationDate, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</b></TableCell>
                </TableRow>
                {/* <TableRow key="registrationNumber" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Numéro d'inscription</TableCell>
                  <TableCell align="left"><b>{agent.registrationNumber}</b></TableCell>
                </TableRow> */}
                <TableRow key="sponsor" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Parrain</TableCell>
                  <TableCell align="left"><b>{agent.sponsor ? agent.sponsor.username : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="username" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom d'utilisateur</TableCell>
                  <TableCell align="left"><b>{agent.username ? agent.username : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="email" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Email</TableCell>
                  <TableCell align="left"><b>{agent.user.email ? agent.user.email : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="lastname" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom de famille</TableCell>
                  <TableCell align="left"><b>{agent.user.lastname ? agent.user.lastname : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="fisrtnames" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Prénom(s)</TableCell>
                  <TableCell align="left"><b>{agent.user.fisrtnames ? agent.user.fisrtnames : '-'}</b></TableCell>
                </TableRow>
                <TableRow key="status" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Statut</TableCell>
                  <TableCell align="left"><Chip label={agentStatus[agent.status]} color="primary" /></TableCell>
                </TableRow>


              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start" }}>

            {
              agent.status != -1 &&
              <Button variant="outlined" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
                onClick={isDeactivateAgentMutationLoading ? null : async () => {
                  await deactivateAgent(agent.id)
                }}>
                Désactiver l'agent
              </Button>
            }
            {
              agent.status != 1 &&
              <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
                onClick={isActivateAgentMutationLoading ? null : async () => {
                  await activateAgent(agent.id)
                }}>
                {activateAgentText[agent.status]}
              </Button>
            }

          </div>
          <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
            onClick={() => {
              navigate(`/admin/agents/${agent.id}/settings/username`)
            }}>
            Changer le nom d'utilisateur de l'agent
          </Button>
          <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
            onClick={() => {
              navigate(`/admin/agents/${agent.id}/settings/sponsor`)
            }}>
            Changer le parrain de l'agent
          </Button>
          <br />
          <Button disabled={isLoginWithAdminPrivilegesMutationLoading} variant="contained" color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
            onClick={handleClickOpen}>
            Se connecter au compte de l'agent
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              onSubmit: async (event) => {
                
                try {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries((formData).entries());
                  console.log(formJson);
                  const otp = formJson.otp;
                  let datas = {
                    "agentId": agent.id,
                    "otp": otp
                  }
                  
                  let res = await loginWithAdminPrivileges(datas);
                  console.log("RES AUTH LOGIN WITH ADMIN PRIVILEGE", res);
                  handleClose();

                  if(res.roles.includes("ROLE_ADMIN")) navigate('/admin');
                  else if(res.roles.includes("ROLE_AGENT")) {
                    await dispatch(setAgentStatus(res?.agent?.status));
                    navigate('/agent');
                  }
                  
                }
                catch (err) {
                  setErrorMessage(err.data ? (err.data.message ? err.data.message : err.data) : err.message);
                }
              },
            }}
          >
            <DialogTitle style={{color:"white"}}>Connexion au compte d'un agent</DialogTitle>
            <DialogContent>
              <DialogContentText style={{color:"white"}}>
                Pour pouvoir vous connecter sur le compte de l'agent souhaité, veuillez entrer votre Code PIN
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="otp"
                name="otp"
                label="Code PIN"
                type="text"
                fullWidth
                variant="standard"
                sx={{
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'white',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: 'white',
                  },
                  '& .MuiInput-underline:hover:before': {
                    borderBottomColor: 'white',
                  },
                }}
              />
              { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}
              <DialogContentText style={{color:"white"}}>
                Pour vous authentifier <Button disabled={isRequestOTPMutationLoading} variant="clear" onClick={async ()=>{
                  try{
                    await requestOTP({});
                  }
                  catch (err) {
                    setErrorMessage(err.data ? (err.data.message ? err.data.message : err.data) : err.message);
                  }
                }}>Demander un code PIN</Button>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{color:"white"}}>Annuler</Button>
              <Button type="submit" style={{color:"white"}}>Se connecter</Button>
            </DialogActions>
          </Dialog>
          <br />
          <h3>Liste des pièces justificatives :</h3>
          {(agent.supportingDocuments.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucune pièce justificative mentionnée.</Alert>}
          {(agent.supportingDocuments.length != 0) &&
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Nom du fichier</TableCell>
                    <TableCell>Type du document</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Actions</TableCell>

                    <TableCell></TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {agent.supportingDocuments.map((document, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{document.filename}</TableCell>
                      <TableCell >{documentTypes[document.documentType]}</TableCell>

                      <TableCell >{documentStatus[document.status]}</TableCell>
                      <TableCell>
                        {
                          document.status == 0 &&
                          <Button variant="outlined" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
                            onClick={isRejectSupportingDocumentMutationLoading ? null : async () => {
                              await rejectSupportingDocument(document.id)
                            }}>
                            Rejeter
                          </Button>
                        }
                        {
                          document.status == 0 &&
                          <Button variant="contained" color="secondary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none', margin: 5 }}
                            onClick={isApproveSupportingDocumentMutationLoading ? null : async () => {
                              await approveSupportingDocument(document.id)
                            }}>
                            Valider
                          </Button>
                        }
                      </TableCell>

                      {
                        <TableCell style={{ maxWidth: 275 }}>


                          <AnimateButton>
                            <Button variant="contained" disabled={document.filepath == null} color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                              onClick={async () => {
                                window.open(`${process.env.REACT_APP_SERVER_URL}/${document.filepath}`, "_blank")
                              }}>
                              Consulter
                            </Button>
                          </AnimateButton>
                        </TableCell>
                      }

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          <br />
          <h3>Liste des contrats :</h3>
          {(agent.agentContracts.length == 0) && <Alert severity="info" style={{ marginTop: 10 }}>Aucun contrat signé.</Alert>}
          {(agent.agentContracts.length != 0) &&
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Date du contrat</TableCell>
                    <TableCell>Nom et prénoms</TableCell>
                    <TableCell>Adresse</TableCell>
                    <TableCell>Code postal</TableCell>
                    <TableCell>Ville</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>N°SIREN</TableCell>



                    <TableCell></TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {agent.agentContracts.map((document, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{document?.createdAt ? moment(document.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MM-YYYY-MM à HH:mm') : "-"}</TableCell>
                      <TableCell >{document.lastname}</TableCell>
                      <TableCell >{document.address}</TableCell>
                      <TableCell >{document.postal_code}</TableCell>
                      <TableCell >{document.city}</TableCell>
                      <TableCell >{document.email}</TableCell>
                      <TableCell >{document.phone_number}</TableCell>
                      <TableCell >{document.siren}</TableCell>


                      {
                        <TableCell style={{ maxWidth: 275 }}>
                          <AnimateButton>
                            <Button variant="contained" disabled={document.filepath == null} color="primary" sx={{ boxShadow: 'none' }} style={{ textTransform: 'none' }}
                              onClick={async () => {
                                window.open(`${process.env.REACT_APP_SERVER_URL}/${document.filepath}`, "_blank")
                              }}>
                              Voir le contrat
                            </Button>
                          </AnimateButton>
                        </TableCell>
                      }

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {/* <br />
          <h3>Bulletin de commission :</h3>
          <AdminAgentCommission agentId={params.agentId} />

          <br />
          <h3>Bonus :</h3>
          <AdminAgentBonuses agentId={params.agentId} /> */}
          
        </>
      }




    </MainCard>
  );
}

export default AdminAgentDetailsPage;
