import { useLoginWithUserPrivilegesMutation, useRequestUserOTPMutation } from 'apis/auth.api';
import { mlmApi, useAddNewSupportingDocumentMutation, useChangePasswordMutation, useChangeUsernameMutation, useCreateAgentInvoiceMutation, useCreateAgentSalesMarginMutation, useCreateCustomerFileMutation, useDeleteAgentInvoiceMutation, useDeleteAgentSalesMarginMutation, useDeleteCustomerFileMutation, useDeleteSupportingDocumentMutation, useLazyGetAgentInfosQuery, useRegisterAgentMutation, useRegisterNewAgentMutation, useSaveContractMutation, useSaveRibMutation } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { is } from 'immutable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setUserRoles, setUserToken } from 'slices/auth.slice';
import { setAgentStatus } from 'slices/mlm.slice';
export const useMlmService = () => {
  const navigate = useNavigate();
  const [registerAgentMutation, { isLoading: isRegisterAgentMutationLoading }] = useRegisterAgentMutation();
  const [registerNewAgentMutation, { isLoading: isRegisterNewAgentMutationLoading }] = useRegisterNewAgentMutation();
  const [changePasswordMutation, { isLoading: isChangePasswordMutationLoading }] = useChangePasswordMutation();
  const [changeUsernameMutation, { isLoading: isChangeUsernameMutationLoading }] = useChangeUsernameMutation();

  const [saveContractMutation, { isLoading: isSaveContractMutationLoading }] = useSaveContractMutation();
  const [trigger] = useLazyGetAgentInfosQuery();
  const [deleteCustomerFileMutation, { isLoading: isDeleteCustomerFileLoading }] = useDeleteCustomerFileMutation();
  const [createCustomerFileMutation, { isLoading: isCreateCustomerFileLoading }] = useCreateCustomerFileMutation();
  const [saveRibMutation, { isLoading: isSaveRibMutationLoading }] = useSaveRibMutation();
  const [addNewSupportingDocumentMutation, { isLoading: isAddNewSupportingDocumentMutationLoading }] = useAddNewSupportingDocumentMutation();
  const [deleteSupportingDocumentMutation, { isLoading: isDeleteSupportingDocumentMutationLoading }] = useDeleteSupportingDocumentMutation();

  const [requestUserOTPMutation, { isLoading: isRequestUserOTPLoading }] = useRequestUserOTPMutation();
  const [loginWithUserPrivilegesMutation, { isLoading: isLoginWithUserPrivilegesLoading }] = useLoginWithUserPrivilegesMutation();

  const [deleteAgentInvoiceMutation, { isLoading: isDeleteAgentInvoiceLoading }] = useDeleteAgentInvoiceMutation();
  const [createAgentInvoiceMutation, { isLoading: isCreateAgentInvoiceLoading }] = useCreateAgentInvoiceMutation();


  const [deleteAgentSalesMarginMutation, { isLoading: isDeleteAgentSalesMarginLoading }] = useDeleteAgentSalesMarginMutation();
  const [createAgentSalesMarginMutation, { isLoading: isCreateAgentSalesMarginLoading }] = useCreateAgentSalesMarginMutation();

  const dispatch = useDispatch();
  const registerAgent = async (datas) => {
    try {
      const result = await registerAgentMutation(datas).unwrap();
      dispatch(mlmApi.util.resetApiState());
      dispatch(stripeApi.util.resetApiState());
      // toast(`Votre inscription en tant qu'agent a été terminé avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const registerNewAgent = async (datas) => {
    try {
      const result = await registerNewAgentMutation(datas).unwrap();
      dispatch(mlmApi.util.resetApiState());
      dispatch(stripeApi.util.resetApiState());
      // toast(`L'inscription du nouvel agent dans votre réseau a été terminé avec succès.`);

      return result;
    } catch (error) {
      throw error;
    }
  };

  const saveContract = async (datas) => {
    try {
      const result = await saveContractMutation(datas).unwrap();
      let agentInfos = await trigger({}, false).unwrap()
      console.log("agentInfos after saving contract", agentInfos)
      await dispatch(setAgentStatus(agentInfos?.status));
      return result;
    } catch (error) {
      throw error;
    }
  };

  const saveRib = async (datas) => {
    try {
      const result = await saveRibMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const changePassword = async (datas) => {
    try {
      const result = await changePasswordMutation(datas).unwrap();
      // toast(`Votre mot de passe a été modifié avec succès.`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const changeUsername = async (datas) => {
    try {
      const result = await changeUsernameMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const deleteCustomerFile = async (datas) => {
    try {
      const result = await deleteCustomerFileMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const createCustomerFile = async (datas) => {
    try {
      const result = await createCustomerFileMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const deleteSupportingDocument = async (datas) => {
    try {
      const result = await deleteSupportingDocumentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };
  const addNewSupportingDocument = async (datas) => {
    try {
      const result = await addNewSupportingDocumentMutation(datas).unwrap();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const loginWithUserPrivileges = async (datas) => {
      try {
        const result = await loginWithUserPrivilegesMutation(datas).unwrap();
  
        // Batch user-related dispatches
        await Promise.all([
          dispatch(setUserToken(result.token)),
          dispatch(setUserRoles(result.roles))
        ]);
  
        
        return result;
      } catch (error) {
        throw error;
      }
    };
    const requestUserOTP = async (datas) => {
      try {
        const result = await requestUserOTPMutation(datas).unwrap();
        return result;
      } catch (error) {
        throw error;
      }
    };

    const deleteAgentInvoice = async (datas) => {
      try {
        const result = await deleteAgentInvoiceMutation(datas).unwrap();
  
        return result;
      } catch (error) {
        throw error;
      }
    };
    const createAgentInvoice = async (datas) => {
      try {
        const result = await createAgentInvoiceMutation(datas).unwrap();
  
        return result;
      } catch (error) {
        throw error;
      }
    };

    const deleteAgentSalesMargin = async (datas) => {
      try {
        const result = await deleteAgentSalesMarginMutation(datas).unwrap();
  
        return result;
      } catch (error) {
        throw error;
      }
    };
    const createAgentSalesMargin = async (datas) => {
      try {
        const result = await createAgentSalesMarginMutation(datas).unwrap();
  
        return result;
      } catch (error) {
        throw error;
      }
    };
  return { createAgentInvoice, createAgentSalesMargin, deleteAgentInvoice, deleteAgentSalesMargin, isCreateAgentInvoiceLoading, isCreateAgentSalesMarginLoading, isDeleteAgentInvoiceLoading, isDeleteAgentSalesMarginLoading,
    requestUserOTP, loginWithUserPrivileges, isRequestUserOTPLoading, isLoginWithUserPrivilegesLoading, deleteSupportingDocument, addNewSupportingDocument, isDeleteSupportingDocumentMutationLoading, isAddNewSupportingDocumentMutationLoading,changeUsername, isChangeUsernameMutationLoading,createCustomerFile, deleteCustomerFile, isCreateCustomerFileLoading, isDeleteCustomerFileLoading, saveContract, isSaveContractMutationLoading, registerAgent, isRegisterAgentMutationLoading, registerNewAgent, isRegisterNewAgentMutationLoading, changePassword, isChangePasswordMutationLoading, saveRib, isSaveRibMutationLoading };
};