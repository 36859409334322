import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Google from 'assets/images/icons/social-google.svg';
import { useAuthService } from 'services/auth.service';
import { useMlmService } from 'services/mlm.service';
import { useNavigate, useParams } from 'react-router';
import { useGetAgentDetailsQuery } from 'apis/mlm.api';
import { useAdminService } from 'services/admin.service';
import { useFindAgentQuery } from 'apis/admin.api';

// ============================|| FIREBASE - LOGIN ||============================ //

const AdminChangeSponsorOfAgent = ({ ...others }) => {
  const params = useParams();
    const { data: agent } = useFindAgentQuery(params.agentId);
  const theme = useTheme();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const [checked, setChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const {changeSponsorOfAgent, isChangeSponsorOfAgentMutationLoading} = useAdminService();
  const ref = useRef(null);
  useEffect(() => {
      if (agent == null) return;
      ref.current.setFieldValue("sponsor", agent["sponsor"] ? agent["sponsor"]["username"] : "");
    }
        , [agent]);
  return (
    <>  

      <Formik
      innerRef={ref}
        initialValues={{
          sponsor: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          sponsor: Yup.string().max(255).optional()
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setErrorMessage(null);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            const datas = {
              "sponsor": values["sponsor"],
              "agentId": params.agentId

            }
            const res = await changeSponsorOfAgent(datas);

            console.log('Result:', res);
            navigate("/agent");
          } catch (err) {
            console.log(err);
            setErrorMessage(err.data ? err.data.message : err.message);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form>
            <FormControl fullWidth error={Boolean(touched.sponsor && errors.sponsor)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-sponsor">Parrain</InputLabel>
              <OutlinedInput
                id="outlined-adornment-sponsor"
                type="text"
                value={values.sponsor}
                name="sponsor"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Parrain"
                inputProps={{}}
              />
              {touched.sponsor && errors.sponsor && (
                <FormHelperText error id="standard-weight-helper-text-sponsor">
                  {errors.sponsor}
                </FormHelperText>
              )}
            </FormControl>



            
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button disableElevation disabled={isChangeSponsorOfAgentMutationLoading} fullWidth size="large" type="submit" variant="contained" color="secondary" style={{textTransform:'none'}}>
                  Changer le parrain
                </Button>
              </AnimateButton>
            </Box>
          </Form>
        )}
      </Formik>
      { errorMessage && <Alert severity="error" style={{marginTop:10}}>{errorMessage}</Alert>}

    </>
  );
};

export default AdminChangeSponsorOfAgent;
