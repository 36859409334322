// assets
import { IconDashboard, IconWallet, IconList, IconFileInvoice, IconMoneybag } from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconWallet, IconList,IconFileInvoice };


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const me = {
  id: 'me',
  title: 'Mon compte',
  caption: 'Gérez votre compte',
  type: 'group',
  roles: ['ROLE_AGENT'],
  children: [
    // {
    //   id: 'ca',
    //   title: "Historique CA",
    //   type: 'item',
    //   url: '/agent/me/ca',
    //   icon: icons.IconList, 
    //   breadcrumbs: false
    // },
    {
      id: 'ca_summary',
      title: "CA & Commissions",
      type: 'item',
      url: '/agent/me/ca_summary',
      icon: icons.IconList, 
      breadcrumbs: false
    },
    // {
    //   id: 'ca_by_day',
    //   title: "Historique CA par jour",
    //   type: 'item',
    //   url: '/agent/me/ca_by_day',
    //   icon: icons.IconList, 
    //   breadcrumbs: false
    // },
    // {
    //   id: 'ca_by_month',
    //   title: "Historique CA par mois",
    //   type: 'item',
    //   url: '/agent/me/ca_by_month',
    //   icon: icons.IconList, 
    //   breadcrumbs: false
    // },
    // {
    //   id: 'ca_by_year',
    //   title: "Historique CA par année",
    //   type: 'item',
    //   url: '/agent/me/ca_by_year',
    //   icon: icons.IconList, 
    //   breadcrumbs: false
    // },
    {
      id: 'orders',
      title: 'Mes commandes',
      type: 'item',
      url: '/agent/me/orders',
      icon: icons.IconFileInvoice, 
      breadcrumbs: false
    },
    // {
    //   id: 'invoices',
    //   title: 'Mes factures',
    //   type: 'item',
    //   url: '/agent/me/invoices',
    //   icon: icons.IconFileInvoice, 
    //   breadcrumbs: false
    // },
    // {
    //   id: 'sales_margin',
    //   title: 'Marges de vente',
    //   type: 'item',
    //   url: '/agent/me/sales_margin',
    //   icon: IconMoneybag, 
    //   breadcrumbs: false
    // },







    // {
    //   id: 'accounts',
    //   title: 'Mes portefeuilles',
    //   type: 'item',
    //   url: '/agent/me/accounts',
    //   icon: icons.IconWallet, 
    //   breadcrumbs: false
    // },
    
    // {
    //   id: 'operations',
    //   title: 'Mes opérations',
    //   type: 'item',
    //   url: '/agent/me/operations',
    //   icon: icons.IconList, 
    //   breadcrumbs: false
    // }
  ]
};

export default me;
