// material-ui
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Chip, Link, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFindAgentQuery } from 'apis/admin.api';
import { useGetAgentDetailsQuery, useGetCustomerFileQuery } from 'apis/mlm.api';
import { use } from 'react';

// ==============================|| SAMPLE PAGE ||============================== //

const AgentCustomerFilesDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: customer_file, isLoading } = useGetCustomerFileQuery(params.customerFileId);
  useEffect(() => {
    if (customer_file == null) return;
    console.log(customer_file);
  }, [customer_file]);

  const customer_fileStatus = {
    "1": "Actif",
    "-1": "Inactif"
  }

  return (
    <MainCard title={`Détails de la fiche client`}>
       {isLoading  && <Alert severity="info" style={{ marginTop: 10 }}>En cours de chargement ...</Alert>}
      {customer_file &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
              <TableRow key="date_registered" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Date d'inscription</TableCell>
                  <TableCell align="left">{customer_file.date_registered}</TableCell>
                </TableRow>
              <TableRow key="username" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom d'utilisateur du client</TableCell>
                  <TableCell align="left">{customer_file.username}</TableCell>
                </TableRow>
                <TableRow key="last_name" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Nom du client</TableCell>
                  <TableCell align="left">{customer_file.last_name}</TableCell>
                </TableRow>
                <TableRow key="first_name" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Prénom du client</TableCell>
                  <TableCell align="left">{customer_file.first_name}</TableCell>
                </TableRow>
                <TableRow key="email" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Email du client</TableCell>
                  <TableCell align="left">{customer_file.email}</TableCell>
                </TableRow>
                <TableRow key="city" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Ville</TableCell>
                  <TableCell align="left">{customer_file.city}</TableCell>
                </TableRow>
                <TableRow key="country" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Pays</TableCell>
                  <TableCell align="left">{customer_file.country}</TableCell>
                </TableRow>

                <TableRow key="own_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Achats perso</TableCell>
                  <TableCell align="left">{`${customer_file?.own_sales ? parseFloat(customer_file?.own_sales).toFixed(2) : "0"} €`} ({customer_file.own_num_items_sold} articles)</TableCell>
                </TableRow>
                <TableRow key="sponsored_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Achats clients</TableCell>
                  <TableCell align="left">{`${customer_file?.sponsored_sales ? parseFloat(customer_file?.sponsored_sales).toFixed(2) : "0"} €`} ({customer_file.sponsored_num_items_sold} articles)</TableCell>
                </TableRow>
                <TableRow key="total_sales" style={{ width: 175 }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Chiffre d'affaire</TableCell>
                  <TableCell align="left"><b>{`${customer_file?.total_sales ? parseFloat(customer_file?.total_sales).toFixed(2) : "0"} €`} ({customer_file.num_items_sold} articles)</b></TableCell>
                </TableRow>
               
               
                

              </TableBody>
            </Table>
          </TableContainer>
        </>
      }




    </MainCard>
  );
}

export default AgentCustomerFilesDetails;
